<template>
    <section class="h-auto leaderboard-bg py-8 lg:py-14 px-6">
        <div class="flex flex-col justify-center items-center">
            <div>
                <img src="../assets/prelead-title.png" alt="prevlead">
            </div>
        </div>
        <!-- <div class="text-center text-white text-xl mt-6 md:text-2xl font-bold" style="font-family: Secular One, sans-serif;">We do apologize for this inconvenience. We'll be back soon.</div> -->
        
        <div v-motion-pop-visible class="text-center text-xl text-[#FFD700] px-2 mt-4 lg:mt-10 uppercase font-base tracking-wider" style="font-family: Secular One, sans-serif;">All wagers are counted during the inclusive dates: <span class="text-white"> &nbsp;{{ prevStartDate }} - {{ prevEndDate }}</span> </div>
        <div v-if="prevLoading == true" class="text-center text-white text-xl md:text-4xl mt-10 font-bold" style="font-family: Secular One, sans-serif;">Loading Previous Leaderboard...</div>
        <div v-if="prevLoading == false"  class="hidden md:block"> 
        
        
          <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Secular One, sans-serif;">
            <table class="w-full lg:w-auto border-separate border-spacing-y-6">
              <thead v-motion-slide-visible-bottom class="rounded bg-[#998100]" style="font-family: Zen Dots, sans-serif;">
                <tr> 
                  <th
                    class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-light text-left rounded-l">
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-light text-center">
                    User
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-light text-center">
                    Amount
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-light text-right rounded-r">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in prevLeadData" :key="index" style="font-family: Russo One, sans-serif;">
                  <td
                    class="px-1 md:px-[45px] lg:px-10 py-2 md:py-8 whitespace-nowrap text-[20px] font-base text-left uppercase" style="font-family: Zen Dots, sans-serif;">
                      <div class="text-white tracking-[4px]">{{ getOrdinalSuffix(index + 1) }}</div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center tracking-[4px]">
                    {{ item.username }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center tracking-[4px]">
                    ${{ item.wagerAmount.toFixed(2) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[20px] text-[#39FF14] font-black text-right tracking-[4px]">
                    {{ getPackdrawRewardValue(index)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="prevLoading == false" class="block md:hidden">
          <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Russo One, sans-serif;">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left">
                    Ranks
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                    User
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                    Amount
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in prevLeadData" :key="index">
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                    {{ item.username }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                    ${{ item.wagerAmount.toFixed(2) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-[#39FF14] font-normal text-right">
                    {{ getPackdrawRewardValue(index)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
       
    </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
export default {
    data(){
        return {
            prevLeadData: [],

            prevStartDate: null,
            prevEndDate: null,

            prevLoading: false
        }
    },
    methods: {
        async initPrevLead(){
            const baseUrl = process.env.VUE_APP_BASE_API;
            // const token = process.env.VUE_APP_AUTH_TOKEN;
            // const userid = process.env.VUE_APP_ROOBET_USER_ID;
            // const leaderboardStartDate = process.env.VUE_APP_LEADERBOARD_START_DATE;
            // const leaderboardDuration = process.env.VUE_APP_LEADERBOARD_DURATION;

            this.prevLoading = true

            // await axios.get(`${baseUrl}/api/roobet/leaderboard?previous=true&userId=` + userid + '&leaderboardStartDate=' + leaderboardStartDate + '&leaderboardDuration=' + leaderboardDuration,{
            // headers: {
            //     'Authorization': `Bearer ${token}`,
            //     'x-api-key': process.env.VUE_APP_X_API_KEY,
            //     'x-api-name': process.env.VUE_APP_X_API_NAME
            // },
            // })
            await axios.get(
              `${baseUrl}/api/leaderboard/packdraw?previous=true`,
              {headers: { 'x-api-key': process.env.VUE_APP_X_API_KEY }}
            ).then(res => {
                const leaderboard = res.data.leaderboard

                this.prevStartDate = moment(res.data.startDate).format('MMMM DD, YYYY')
                this.prevEndDate = moment(res.data.endDate).subtract(1, 'days').format('MMMM DD, YYYY')

                this.prevLeadData = leaderboard.slice(0, 10).map((item) => {
                return item;
            });

            this.prevLoading = false
            });
        },
        getOrdinalSuffix(n) {
            const s = ['th', 'st', 'nd', 'rd'],
                v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        },
        getPackdrawRewardValue(rank) {
            const prizes = JSON.parse(process.env.VUE_APP_PACKDRAW_PREV_PRIZES);
            return '$'+ prizes[rank];
        },
    },
    mounted(){
        this.initPrevLead()
    }
}
</script>

<style scoped>
.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center ;
}
</style>
