<template>
    <section class="h-auto">
      <div class="flex justify-center items-center px-8 md:px-8 lg:pt-6" id="leaderboard">
        <div
          class="flex flex-col justify-center items-center mb-14 p-8 rounded-3xl">
          
          <!-- Leaderboard Section -->
          <div v-motion-slide-visible-bottom class="text-center text-white font-thin rounded-2xl px-3 py-2 mt-4 md:mt-2 bg-gradient-to-r from-[#412F00] to-[#A77800]" style="font-family: Russo One, sans-serif;">
                {{ loading == true ? "Calculating time": roobetTimer }}
          </div>
          <!-- <div v-motion-pop-visible class="flex justify-center text-center text-xl text-[#FFD700] px-2 mt-4 lg:mt-10 uppercase font-base tracking-wider" style="font-family: Secular One, sans-serif;">roobet leaderboard ends every week. <br> ONLY THE TOP 15 ARE REWARDED. YOU WILL BE REWARDED VIA ROOBET <br> SO MAKE SURE TO SIGN UP UNDER CODE: MIK</div> -->
          <div v-motion-pop-visible class="text-center text-xl text-[#FFD700] px-2 mt-4 lg:mt-10 uppercase font-base tracking-wider" style="font-family: Secular One, sans-serif;">All wagers are counted during the inclusive dates:
                <span class="text-white">{{ roobetStartDate }} - {{ roobetEndDate }}</span>
                <!--<br>Leaderboard ends every Sunday 11: 59:59PM UTC. 
                <br>New leaderboard data will be available by Monday 12:00 AM UTC-->
          </div>
          <!-- <div class="text-center text-white text-xl md:text-2xl font-bold" style="font-family: Secular One, sans-serif;">We do apologize for this inconvenience. We'll be back soon.</div> -->
          <div v-if="loading == true" class="text-center text-white text-xl md:text-4xl mt-10 font-bold" style="font-family: Secular One, sans-serif;">Loading Leaderboard...</div>
          <div v-if="loading == false"  class="hidden md:block">
            <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Secular One, sans-serif;">
              <table class="w-full lg:w-auto border-separate border-spacing-y-6">
                <thead v-motion-slide-visible-bottom class="rounded bg-[#998100]" style="font-family: Zen Dots, sans-serif;">
                  <tr> 
                    <th
                      class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-light text-left rounded-l">
                      Ranks
                    </th>
                    <th
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-light text-center">
                      User
                    </th>
                    <th
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-light text-center">
                      Amount
                    </th>
                    <th
                      class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-light text-right rounded-r">
                      Prize
                    </th>
                  </tr>
                </thead>
                <div class="mt-4"></div>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in top3" :key="index" class="bg-[#998100] shadow-xl shadow-[#998100] bg-opacity-60">
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-4 whitespace-nowrap text-4xl font-base text-left rounded-l uppercase">
                        <div class="text-white tracking-[5px]" style="text-shadow: 2px 2px 5px white;">{{ getOrdinalSuffix(index + 1) }}</div>
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center tracking-[4px]">
                      {{ item.username }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center tracking-[4px]">
                      ${{ item.wagerAmount.toFixed(2)}}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-4 whitespace-nowrap text-[18px] text-[#39FF14] font-base text-right rounded-r tracking-[5px]" style="font-family: Russo One, sans-serif;">
                      {{ getPackdrawRewardValue(index)}}
                    </td>
                  </tr>
                </tbody>
                <div class="mt-8"></div>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in topRest" :key="index" style="font-family: Russo One, sans-serif;">
                    <td
                      class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[20px] font-base text-left uppercase" style="font-family: Zen Dots, sans-serif;">
                        <div class="text-white tracking-[4px]">{{ getOrdinalSuffix(index + 4) }}</div>
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center tracking-[4px]">
                      {{ item.username }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center tracking-[4px]">
                      ${{ item.wagerAmount.toFixed(2) }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[20px] text-[#39FF14] font-black text-right tracking-[4px]">
                      {{ getPackdrawRewardValue(index + 3)}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="loading == false"  class="block md:hidden">
            <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Russo One, sans-serif;">
              <table class="w-full lg:w-auto">
                <thead v-motion-slide-visible-bottom>
                  <tr>
                    <th
                      class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left">
                      Ranks
                    </th>
                    <th
                      class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                      User
                    </th>
                    <th
                      class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                      Amount
                    </th>
                    <th
                      class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right">
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in topForMobile" :key="index">
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left">
                      {{ getOrdinalSuffix(index + 1) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                      {{ item.username }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                      ${{ item.wagerAmount.toFixed(2) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-[#39FF14] font-normal text-right">
                      {{ getPackdrawRewardValue(index)}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <div v-if="loading == false" v-motion-slide-visible-bottom class="flex justify-center text-white mt-8 lg:mt-14 uppercase font-semibold tracking-wider px-2 md:px-4 lg:px-48 2xl:px-96 2xl:ml-4" style="font-family: Roboto, serif;">
            Your wagers on Roobet will count towards the leaderboard at the following weights based on the games you are playing. This helps prevent leaderboard abuse:
  
            <br><br>*Games with an RTP under 96% will contribute 100% of the amount wagered to the leaderboard.
            <br>*Games with an RTP of 97% will contribute 50% of the amount wagered to the leaderboard.
            <br>*Games with an RTP of 98- 99% and above will contribute 10% of the amount wagered to the leaderboard.
            <br>*Only house games and slots count towards leaderboard.
          </div> -->
        </div>
      </div>
    </section>
  
    <PackdrawPrevLeaderboardSection ref="PackdrawPrevLeaderboardSection"/>
  </template>
  
  <script>
  import axios from 'axios';
  import moment from 'moment-timezone';
   import PackdrawPrevLeaderboardSection from './PackdrawPrevLeaderboardSection.vue';
  
  export default {
    components: {
       PackdrawPrevLeaderboardSection
    },
    data() {
      return {
        top3: [],
        topRest: [],
        topForMobile: [],
  
        roobetTimer: '',
        rooberLeaderboard: [],
  
        roobetStartDate: null,
        roobetEndDate: null,
  
        roobetNotFormattedEndDate: null,
  
        loading: false
      };
    },
    methods: {
      async init() {
  
        const baseUrl = process.env.VUE_APP_BASE_API;
        // const token = process.env.VUE_APP_AUTH_TOKEN;
        // const userid = process.env.VUE_APP_ROOBET_USER_ID;
        // const leaderboardStartDate = process.env.VUE_APP_LEADERBOARD_START_DATE;
        // const leaderboardDuration = process.env.VUE_APP_LEADERBOARD_DURATION;
        // const leaderboardTimeZone = process.env.VUE_APP_ROOBET_TIMEZONE
  
        this.loading = true
  
          // await axios.get(`${baseUrl}/api/roobet/leaderboard?userId=` + userid + '&leaderboardStartDate=' + leaderboardStartDate + '&leaderboardDuration=' + leaderboardDuration + '&timezone=' + leaderboardTimeZone,{
          //   headers: {
          //     'Authorization': `Bearer ${token}`,
          //     'x-api-key': process.env.VUE_APP_X_API_KEY,
          //     'x-api-name': process.env.VUE_APP_X_API_NAME
          //   },
          // })
          await axios.get(
            `${baseUrl}/api/leaderboard/packdraw`,
            {headers: { 'x-api-key': process.env.VUE_APP_X_API_KEY }}
          ).then(res => {
            this.rooberLeaderboard = res.data.leaderboard;
            this.roobetStartDate = moment(res.data.startDate).format('MMMM DD, YYYY')
            this.roobetEndDate = moment(res.data.endDate).subtract(1, 'days').format('MMMM DD, YYYY')
            this.roobetNotFormattedEndDate = res.data.endDate
  
            const leaderboard = this.rooberLeaderboard
              this.top3 = leaderboard.slice(0, 3).map((item) => {
                return item;
              });
  
              this.topRest = leaderboard.slice(3, 10).map((item) => {
                return item;
              });
  
              this.topForMobile = leaderboard.slice(0, 10).map((item) => {
                return item;
            });
  
            this.startRoobetTimer()
  
            this.loading = false
          });
      },
      getOrdinalSuffix(n) {
        const s = ['th', 'st', 'nd', 'rd'],
          v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      },
      getPackdrawRewardValue(rank) {
        const prizes = JSON.parse(process.env.VUE_APP_PACKDRAW_CURRENT_PRIZES);
        return '$'+ prizes[rank];
      },
      startRoobetTimer() {
        const getNextTargetDate = () => {
          const now = moment().utc();
          // let targetDate = moment.tz(this.roobetNotFormattedEndDate, 'America/New_York').hour(23).minute(59).second(59).subtract(1, 'days')
          let targetDate = moment.utc(this.roobetNotFormattedEndDate);
  
          if (now.isSameOrAfter(targetDate)) {
            targetDate.add(1, 'week');
          }
  
          return targetDate;
        };
  
        let targetDate = getNextTargetDate();
        let roobetTimerInterval;
  
        const updateTimer = () => {
          // const nowEst = moment.tz('America/New_York');
          const nowUTC = moment().utc();
          const distance = targetDate.diff(nowUTC);
  
          if (distance < 0) {
            this.timer = 'Leaderboard ended';
            this.init()
            clearInterval(roobetTimerInterval);
  
            targetDate = getNextTargetDate();
            this.startRoobetTimer();
            this.$refs.PrevLeaderboardSection.initPrevLead()
            
          } else {
            const duration = moment.duration(distance);
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();
  
            this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
          }
        };
  
        updateTimer();
        roobetTimerInterval = setInterval(updateTimer, 1000);
      },
    },
    mounted() {
      this.init();
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  
